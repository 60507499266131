.wrap {
  height: 90%;
  background-color: #000;
  & .shotbutton {
    position: absolute;
    z-index: 100;
    bottom: 0px;
    left: calc(50% - 35px);
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border-color: rgb(0, 0, 0);
    background-color: #fff;
    &:after {
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      display: block;
      content: '';
      width: 60px;
      height: 60px;

      background-color: rgb(0, 0, 0);
      border-radius: 50%;
    }
  }
}

h1 {
  font-size: 18px;
}
h2 {
  font-size: 14px;
}

@media (orientation: landscape) {
  header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    text-align: center;
    & h1 {
      margin-right: 10px;
    }
  }

  h2:after {
    content: 'Take a picture of where you parked.  It is not saved to the photo album.';
  }

  .wrap > section {
    width: 50%;
    height: 100%;
    display: inline-block;
  }
}
@media (orientation: portrait) {
  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
  }

  .landscape {
    display: none;
  }
  h2:after {
    white-space: pre;
    content: 'Take a picture of where you parked. \A It is not saved to the photo album.';
  }

  .wrap > section {
    height: 50%;
    width: 100%;
  }
}

.wrap > section {
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.capture {
  margin: '0 auto';

  position: relative;
  left: 0;
  top: 0;

  & .border {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 20px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
  }

  & video {
    vertical-align: top;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
